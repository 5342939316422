import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const usePaymentSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const stateOptions = [{
    label: '停用', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const typeOptions = [{
    label: '虛擬帳號', value: 'account',
  }, {
    label: '信用卡', value: 'credit',
  }, {
    label: '超商代碼', value: 'store',
  }]

  const ui = {
    stateList: [{
      false: '停用', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
    typeList: [{
      account: '虛擬帳號', credit: '信用卡', store: '超商代碼',
    }, {
      account: 'light-info', credit: 'light-warning', store: 'light-success',
    }],
  }

  const providerOptions = ['ECPay']

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  const blankChartData = {
    series: [65],
    options: {
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['#4683bb'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }

  return {
    ui,
    stateOptions,
    typeOptions,
    providerOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,

    blankChartData,
  }
}

export const usePaymentList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    getMetaListData,
    blankChartData,
  } = usePaymentSetting()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '類別', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '單日限額', key: 'daily_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '單月限額', key: 'monthly_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '單筆下限', key: 'low', sortable: true, searchable: true, select: true,
    },
    {
      label: '單筆上限', key: 'high', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const isTestBusy = ref(false)
  const paymentData = ref(null)
  const searchState = ref(null)
  const searchType = ref(null)
  const searchThirdPartyId = ref(null)
  const isLoadingBusy = ref(false)

  const blankPaymentData = {
    id: null,
    name: null,
    state: false,
    third_party_id: null,
    type: null,
    daily: 0,
    monthly: 0,
    daily_total: 0,
    monthly_total: 0,
    high: 0,
    low: 0,
    config: [],
    // content: null,
    created_at: null,
    updated_at: null,
  }

  const blankPaymentType = {
    id: null,
    name: null,
  }

  const getPaymentData = (...arg) => store.dispatch('admin-payment/getPaymentData', ...arg)
  const setPaymentDelete = (...arg) => store.dispatch('admin-payment/setPaymentDelete', ...arg)
  const setPaymentCreate = (...arg) => store.dispatch('admin-payment/setPaymentCreate', ...arg)
  const setPaymentTest = (...arg) => store.dispatch('admin-payment/setPaymentTest', ...arg)
  const setPaymentUpdate = (...arg) => store.dispatch('admin-payment/setPaymentUpdate', ...arg)

  const getPaymentTypeData = (filter, callback) => {
    isLoadingBusy.value = true
    getMetaListData({ ...filter })
      .then(response => {
        const { data } = response.data.data
        const paymentTypeOptions = data.map(item => {
          const resolve = {
            ...syncObject(blankPaymentType, item),
          }
          return resolve
        })
        store.commit('admin-payment/UPDATE_PAYMENT_TYPE_LIST_STATE', paymentTypeOptions)
        isLoadingBusy.value = false
        callback()
      })
  }

  const getPaymentListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value ? 1 : 0}`
    }

    if (searchThirdPartyId.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};third_party_id: ${searchThirdPartyId.value}`
      } else resolveFilters = `third_party_id: ${searchThirdPartyId.value}`
    }

    if (searchType.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};type: ${searchType.value}`
      } else resolveFilters = `type: ${searchType.value}`
    }

    store.dispatch('admin-payment/getPaymentList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const percentagedaily = item.daily_total === 0 ? 0 : (item.daily_total / item.daily) * 100
          const roundedPercentagedaily = item.daily !== 0 ? percentagedaily.toFixed(1) : 100

          const percentageMonthly = item.monthly_total === 0 ? 0 : (item.monthly_total / item.monthly) * 100
          const roundedPercentageMonthly = item.monthly !== 0 ? percentageMonthly.toFixed(1) : 100
          const resolve = {
            ...syncObject(blankPaymentData, item),
            chartdailyData: JSON.parse(JSON.stringify(blankChartData)),
            chartMonthlyData: JSON.parse(JSON.stringify(blankChartData)),
            percentagedaily: roundedPercentagedaily,
            percentageMonthly: roundedPercentageMonthly,
          }
          resolve.chartdailyData.series[0] = resolve.percentagedaily
          resolve.chartMonthlyData.series[0] = resolve.percentageMonthly
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange, searchType, searchThirdPartyId], () => {
    refetchData()
  })

  return {
    isBusy,
    isTestBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchState,
    searchType,
    searchThirdPartyId,
    timeRange,
    blankPaymentData,
    paymentData,
    refetchData,
    refetchTable,

    getPaymentData,
    setPaymentDelete,
    setPaymentCreate,
    setPaymentTest,
    setPaymentUpdate,
    getPaymentListData,
    getPaymentTypeData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const usePaymentType = () => {
  const {
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    timeRange,
    refDataListTable,
    totalNum,
    dateDefaluteConfig,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankChartData,
  } = usePaymentSetting()

  const paymentType = ref(null)
  const initBusy = ref(false)

  const blankPaymentType = {
    id: null,
    name: null,
    provider: null,
    daily: 0,
    monthly: 0,
    daily_total: 0,
    monthly_total: 0,
    // config_key: [],
    config: [],
    updated_at: null,
    created_at: null,
  }

  const blankSetting = {
    key: null,
    label: null,
    value: null,
  }

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '單日限額', key: 'daily_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '單月限額', key: 'monthly_total', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const getPaymentType = (...args) => store.dispatch('admin-payment/getPaymentType', ...args)
  const setPaymentTypeCreate = (...args) => store.dispatch('admin-payment/setPaymentTypeCreate', ...args)
  const setPaymentTypeUpdate = (...args) => store.dispatch('admin-payment/setPaymentTypeUpdate', ...args)

  const getPaymentTypesData = (ctx, callback) => {
    store.dispatch('admin-payment/getPaymentTypeList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const percentagedaily = item.daily_total === 0 ? 0 : (item.daily_total / item.daily) * 100
          const roundedPercentagedaily = percentagedaily.toFixed(1)

          const percentageMonthly = item.monthly_total === 0 ? 0 : (item.monthly_total / item.monthly) * 100
          const roundedPercentageMonthly = percentageMonthly.toFixed(1)
          const resolve = {
            ...syncObject(blankPaymentType, item),
            chartdailyData: JSON.parse(JSON.stringify(blankChartData)),
            chartMonthlyData: JSON.parse(JSON.stringify(blankChartData)),
            percentagedaily: roundedPercentagedaily,
            percentageMonthly: roundedPercentageMonthly,
          }
          resolve.chartdailyData.series[0] = resolve.percentagedaily
          resolve.chartMonthlyData.series[0] = resolve.percentageMonthly
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    refDataListTable,
    dateDefaluteConfig,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    timeRange,
    refetchData,
    refetchTable,

    paymentType,
    initBusy,
    blankPaymentType,
    blankSetting,
    totalNum,

    getPaymentType,
    getPaymentTypesData,
    setPaymentTypeCreate,
    setPaymentTypeUpdate,

    useAlertToast,
    useHttpCodeAlert,
    syncObject,
  }
}
